// extracted by mini-css-extract-plugin
export const heading = "events-module--heading--2j87c";
export const description__navy = "events-module--description__navy--2MBzS";
export const description__white = "events-module--description__white--3nELy";
export const wrapper = "events-module--wrapper--2jVyC";
export const wrapper__header = "events-module--wrapper__header--3DFPP";
export const wrapper__subject = "events-module--wrapper__subject--xGc58";
export const wrapper__where = "events-module--wrapper__where--b1wv9";
export const wrapper__tasks = "events-module--wrapper__tasks--rC5zb";
export const wrapper__tasks__header = "events-module--wrapper__tasks__header--2wwJU";
export const wrapper__tasks__single = "events-module--wrapper__tasks__single--3CcoO";
export const events__btn = "events-module--events__btn--2k_XC";
export const gcal__btn = "events-module--gcal__btn--DTT5L";
export const newsletter = "events-module--newsletter--NUWen";