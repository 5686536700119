import React, { useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Layout } from "@components/layout"
import { NewsletterForm } from "../blog/post"
import {
  wrapper,
  newsletter,
  wrapper__header,
  wrapper__where,
  wrapper__subject,
  wrapper__tasks,
  wrapper__tasks__single,
  wrapper__tasks__header,
  events__btn,
  gcal__btn,
  fb__btn,
} from "./styles/events.module.scss"
import { AnimatedSection, AnimatedButton } from "@components/shared"
import FbSVG from "./icons/fb.svg"
import GcSVG from "./icons/gcal.svg"
import ZoomSVG from "./icons/zoom.svg"
import GhSVG from "./icons/gh.svg"

const SingleTask = ({ label, url, technology }) => {
  return (
    <div className={wrapper__tasks__single}>
      <p>{technology}</p>
      <AnimatedButton className={gcal__btn}>
        <img src={GhSVG} />
        <a target="_blank" href={url}>
          {label}
        </a>
      </AnimatedButton>
    </div>
  )
}

const Events = ({ pageContext }) => {
  const { page } = pageContext
  const { WP } = useStaticQuery(graphql`
    query {
      WP {
        page(id: "cGFnZToxODkw") {
          ACF_Events {
            event {
              zoom
              subject
              tasks {
                technology
                label
                url
              }
            }
          }
        }
      }
    }
  `)
  const { event } = WP.page.ACF_Events
  const { tasks, zoom, subject } = event
  return (
    <Layout {...page}>
      <AnimatedSection className={wrapper}>
        <h1>Cotygodniowe wydarzenie Gladiatorów Javascriptu</h1>
        <p>
          W każdy czwartek spotykamy się naszą społecznością Gladiatorów, aby
          wspólnie wzbogacać swoją wiedzę, uczyć się pisania lepszego kodu i
          dowiadywać się, jakich błędów należy się wystrzegać.{" "}
        </p>

        <AnimatedButton className={gcal__btn}>
          <img src={FbSVG} />
          <a
            target="_blank"
            href="https://www.facebook.com/events/539891037412148"
          >
            Wydarzenie na facebooku
          </a>
        </AnimatedButton>

        <p>Każdy chętny jest zaproszony!</p>
        <AnimatedButton className={gcal__btn}>
          <img src={GcSVG} />

          <a
            target="_blank"
            href="https://calendar.google.com/event?action=TEMPLATE&tmeid=MjgydTA4ajZsa3RpbGpuYjUxZ3VyZG1icjhfMjAyMTA0MDFUMTgwMDAwWiB0anB2cXRuZHVrcXRodmVxZnE2cDBlbm45c0Bn&tmsrc=tjpvqtndukqthveqfq6p0enn9s%40group.calendar.google.com&scp=ALL"
          >
            Dodaj do kalendarza!
          </a>
        </AnimatedButton>
        <p>
          Na spotkaniu możesz zmierzysz się z wymagającymi zadaniami, a przed
          spotkaniem – oddać swoje rozwiązanie do code review, aby zostało
          omówione na zajęciach.
        </p>

        <div className={wrapper__where}>
          <h5>🔸Gdzie odbędzie się spotkanie?</h5>
          <AnimatedButton className={gcal__btn}>
            <img src={ZoomSVG} />
            <a target="_blank" href={zoom}>
              Prowadź do Zoom'a!
            </a>
          </AnimatedButton>
        </div>

        <div className={wrapper__subject}>
          <h5>
            🔸W tym tygodniu razem z nami poszerzysz swoją wiedzę w temacie:
          </h5>
          <p>{subject}</p>
        </div>
        <div className={wrapper__tasks}>
          <div className={wrapper__tasks__header}>
            <h5>Zadania na ten tydzień:</h5>
          </div>
          {tasks.map((el, i) => {
            return <SingleTask {...el} />
          })}
        </div>
        <p>
          📩Aby zawsze posiadać aktualną wiedzę na temat programowania, naszych
          spotkań i tego czym się zajmujemy – wystarczy dołączyć się do
          newslettera. Już nic Cię nie ominie!
        </p>
      </AnimatedSection>
      <div className={newsletter}>
        <NewsletterForm />
      </div>
    </Layout>
  )
}

export default Events
